import React, { CSSProperties, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { NavButton } from './NavButton';
import { NavBar } from './NavBar';
import { HomeSection } from './HomeSection';
import { MusicSection } from './MusicSection';
import { GigsSection } from './GigsSection';

//Fix for "digital envelope routines unsupported"
//export NODE_OPTIONS=--openssl-legacy-provider

function App() {

    useEffect(() => {
        //launch app

        //redirect to https site if using http (ignore on localhost)
        if (!window.location.href.includes("localhost") && window.location.protocol !== 'https:') 
            window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
            
        //reload whole page when device is rotated
        window.onorientationchange = function() { 
            var orientation = window.orientation; 
                switch(orientation) 
                { 
                    case 0:
                    case 90:
                    case -90: 
                        window.location.reload(); 
                        break; 
                } 
        };

    }, [])

    return (
        <div>
            <HomeSection />
            <div id="music"/>
            <NavBar />
            <MusicSection />
            <div id="gigs"/>
            <GigsSection />
        </div>
    );
}

export default App;
