import { AppBar } from 'material-ui-core';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { NavButton } from './NavButton';
import { Util } from './Util';

export function NavBar() {

    let [state, setState] = useState({ sticky: false });
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const handleScroll = () => 
        {
            if(!container.current)
                return;
            
            //make scrollbar sticky when user has scrolled down far enough
            let topOffset = window.pageYOffset - window.innerHeight;
            let navBarIsSticky = topOffset > -container.current.clientHeight;
            setState({ sticky: navBarIsSticky });
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    let containerStyle : CSSProperties = 
    {
        width: "100%",
        height: Util.navBarHeight,//isMobileDevice ? "96px" : "10vh",
        minHeight: "0",
        visibility: Util.isShortWindow ? "hidden" : "visible"
    };

    let stickyContainerStyle : CSSProperties = 
    {
        background: "#222",
        width: "100%",
        height: Util.navBarHeight, //"10vh",
        minHeight: "0",
        zIndex: 1,
        position: "relative",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 32px"
    };

    //stick to top of the screen
    if(state.sticky)
    {
        stickyContainerStyle.position = "fixed";
        stickyContainerStyle.top = 0;
    }

    return (
        <div style={containerStyle} ref={container}>
            <AppBar style={stickyContainerStyle}>
                <NavButton title="HOME" anchor="#" />
                <NavButton title="MUSIC" anchor="#music" />
                <NavButton title="GIGS" anchor="#gigs" />
                {/*<NavButton title="STORE" anchor="#store" />*/}
            </AppBar>
        </div>
    );
}
