import React, { CSSProperties, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { NavButton } from './NavButton';
import { NavBar } from './NavBar';
import { Util } from './Util';

export function HomeSection() {

    let containerStyle : CSSProperties = 
    {
        textAlign: "center",
        verticalAlign: "middle",
        width: "100%",
        height: Util.sectionHeight,//"90vh",
        boxSizing: "border-box",
        overflowX: "hidden",
        position: "relative"
    };

    let artworkStyle : CSSProperties = 
    {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        objectFit: "cover",
        userSelect: "none"
    }

    let titleStyle : CSSProperties = 
    {
        color: "white",
        fontSize: Util.isShortWindow ? "48px" : Util.isMobileDevice ? "64px" : "64px",
        fontFamily: Util.font,
        position: "absolute",
        left: "50%",
        transform: `translate(-50%, ${Util.isMobileDevice ? "0" : "-50%"})`,
        textAlign: "left",
        userSelect: "none",
        display: "block",
    }

    if (Util.isMobileDevice)
    {
        titleStyle.bottom = 0;
    }
    else
    {
        titleStyle.top = "25%";
    }

    let subtitleStyle : CSSProperties = 
    {
        color: "white",
        background: "#00000055",
        fontSize: Util.isMobileDevice ? "12px" : "24px",
        fontFamily: Util.font,
        position: "absolute",
        left: "0",
        bottom: "0",
        margin: 16,
        textAlign: "left",
        userSelect: "none",
        display: "block",
        padding: "16px"
    }

    let iconsContainerStyle : CSSProperties = 
    {
        color: "white",
        fontSize: Util.isMobileDevice ? "12px" : "24px",
        fontFamily: Util.font,
        position: "absolute",
        right: "0",
        bottom: "0",
        margin: "0 0 0 0",
        textAlign: "left",
        userSelect: "none",
        display: "block",
    }

    let iconDivStyle : CSSProperties = 
    {
        background: "#00000055",
        padding: "16px",
        margin: "16px 16px 16px 0",
        verticalAlign: "top",
        display: "inline-block"
    }

    let iconImgStyle : CSSProperties = 
    {
        display: "block",
        width: 24,
        height: 24
    }

    return (
        <div style={containerStyle}>
            <img src={Util.getImageUrl(Util.isMobileDevice ? "fence_tiny.jpg" : "stand_optimised.webp")} style={artworkStyle}></img>
            <div style={titleStyle}><img height={400} src={Util.getImageUrl("cic_logo.png")}/></div>
            <p style={subtitleStyle}>Indie-Pop Band // Manchester UK</p>
            <div style={iconsContainerStyle}>
                <a href="https://www.instagram.com/castles_in_canopy__/" target="_blank"><div style={iconDivStyle}><img style={iconImgStyle} src={Util.getImageUrl("ic_instagram.svg")}></img></div></a>
                <a href="https://www.facebook.com/castlesincanopy" target="_blank"><div style={iconDivStyle}><img style={iconImgStyle} src={Util.getImageUrl("ic_facebook.svg")}></img></div></a>
            </div>
        </div>
    );
}
