export class Colors 
{
    static get clear()
    {
        return "rgba(255, 255, 255, 0)";
    }

    static get white()
    {
        return "#ffffff";
    }

    static get lightGrey()
    {
        return "#eeeeee";
    }

    static get midGrey()
    {
        return "#909396";
    }

    static get darkGrey()
    {
        return "#2b2e31";
    }

    static get darkerGrey()
    {
        return "#1e2123";
    }

    static get veryDarkGrey()
    {
        return "#16181A";
    }

    static get black()
    {
        return "#000000";
    }

    static get tablesPink()
    {
        return "#FF91A3";
    }

    static get tablesBlue()
    {
        return "#A5D0FF";
    }

    static get tablesGreen()
    {
        return "#90EE90";
    }

    static colorWithAlpha(color: string, alpha : number)
    {
        var opacity = Math.round(Math.min(Math.max(alpha || 1, 0), 1) * 255);
        return color + opacity.toString(16).toUpperCase();
    }
}