import React, { CSSProperties, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Util } from './Util';

interface NavButtonProps
{
    title : string,
    anchor : string
}

export function NavButton(props : NavButtonProps) {

    let [state, setState] = useState({ highlight: false });

    let setHighlight = (highlightState : boolean) => 
    {
        setState({ highlight: highlightState });
    };

    let navButtonStyle : CSSProperties = 
    {
        fontFamily: Util.font,
        fontSize: Util.isMobileDevice ? "16px" : "24px",
        fontWeight: state.highlight ? "bold" : 100,
        opacity: state.highlight ? 1 : 0.9,
        color: "#eee",
        padding: "16px",
        display: "inline-block",
        cursor: "pointer"
    }

    return (
        <a href={props.anchor}>
            <div style={navButtonStyle} onMouseOver={() => setHighlight(true)} onMouseLeave={() => setHighlight(false)}>
                {props.title}
            </div>
        </a>
    );
}