import React, { CSSProperties } from 'react';
import logo from './logo.svg';
import './App.css';
import { NavButton } from './NavButton';
import { NavBar } from './NavBar';
import { GigItem } from './GigItem';
import { Util } from './Util';
import { GigItemMoreSoon } from './GigItemMoreSoon';

export function GigsSection() {

    let containerStyle : CSSProperties = 
    {
        textAlign: "center",
        verticalAlign: "middle",
        width: "100%",
        height: Util.isShortWindow ? "600px" : Util.sectionHeight,
        boxSizing: "border-box",
        overflowX: "hidden",
        position: "relative"
    };

    let artworkStyle : CSSProperties = 
    {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        objectFit: "cover",
        userSelect: "none"
    }

    let gigsContainerStyle : CSSProperties = 
    {
        position: "absolute",
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        width: Util.isMobileDevice ? "100%" : "55%", 
        color: "white",
        fontSize: Util.isMobileDevice ? "64px" : "96px",
        fontFamily: Util.font,
        textAlign: "left",
        userSelect: "none",
        display: "block",
        padding: "16px",
        boxSizing: "border-box"
    }

    return (
        <div style={containerStyle}>
            <img src={`${process.env.PUBLIC_URL}/images/walk_blur.jpg`} style={artworkStyle}></img>
            <div style={gigsContainerStyle}>
                <p style={{ marginBottom: 32 }}>Gigs</p>
                {/*<GigItem venue="Gullivers" location="Manchester UK" date="11 Dec 2021" time="7:30pm" ticketsUrl="https://fatso.ma/VeER" />
                <GigItem venue="Gullivers" location="Manchester UK" date="18 Feb 2022" time="7:30pm" ticketsUrl="https://fatso.ma/VqMx" />
                <GigItem venue="Hatch" location="Manchester UK" date="27 Apr 2022" time="7pm" ticketsUrl="https://www.eventbrite.co.uk/e/open-beat-uk-bimm-hatch-spring-tickets-315440118887?aff=erelexpmlt" />
                <GigItem venue="Retro" location="Manchester UK" date="20 July 2023" time="7:30pm" ticketsUrl="https://fatso.ma/X5Oz" />
                <GigItem venue="The Spinning Top" location="Stockport UK" date="22 Oct 2023" time="4pm" ticketsUrl='FREE' />
                <GigItem venue="Off The Square" location="Manchester UK" date="27 Oct 2023" time="7:30pm" ticketsUrl='https://fixr.co/event/castles-in-canopy-plus-special-guests-mavis-when-s-tickets-521479237' />
                <GigItem venue="The Blossoms" location="Stockport UK" date="24 Nov 2023" time="7:30pm" ticketsUrl='https://www.facebook.com/events/1526563201464703/' />*/}
                <GigItem venue="Castle Hotel" location="Manchester UK" date="25 Feb 2024" time="7:30pm" ticketsUrl='SOLD OUT' />
                <GigItem venue="33 Oldham Street" location="Manchester UK" date="2 March 2024" time="7:30pm" ticketsUrl='https://www.skiddle.com/e/37129292/' />
                <GigItem venue="The Blossoms" location="Stockport UK" date="26 April 2024" time="7:30pm" ticketsUrl='FREE ENTRY' />
                <GigItem venue="Shipping Forecast" location="Liverpool UK" date="9 June 2024" time="7:30pm" ticketsUrl='https://www.skiddle.com/whats-on/Liverpool/The-Hold-The-Shipping-Forecast/Big-Condo-Records-Weekender-Frisson-Stage/38304265/' />
                <GigItem venue="Lion's Den" location="Manchester UK" date="6 Sep 2024" time="7:30pm" ticketsUrl='https://skiddle.com/e/39299319' />

                {/*<GigItemMoreSoon />*/}
            </div>
        </div>
    );
}
