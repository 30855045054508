export class Util
{
    static get isMobileDevice()
    {
        return window.matchMedia("only screen and (max-width: 760px)").matches || this.isShortWindow;
    }

    static get isShortWindow()
    {
        return window.innerHeight < 400;
    }

    static get navBarHeight()
    {
        if (this.isShortWindow)
            return 0;

        return this.isMobileDevice ? 64 : 96;
    }

    static get sectionHeight()
    {
        if (this.isShortWindow)
            return window.innerHeight;

        return window.innerHeight - this.navBarHeight;
    }

    static get font()
    {
        return "Arvo, Georgia, serif";
    }

    static getImageUrl(imageName : string)
    {
        return `${process.env.PUBLIC_URL}/images/${imageName}`;
    }
}