import React, { CSSProperties } from 'react';
import logo from './logo.svg';
import './App.css';
import { NavButton } from './NavButton';
import { NavBar } from './NavBar';
import { Util } from './Util';
import { Colors } from './Colors';

interface GitItemProps
{
    venue : string,
    location : string,
    date : string,
    time : string,
    ticketsUrl? : string
}

export function GigItem(props : GitItemProps) {

    let containerStyle : CSSProperties = 
    {
        color: "white",
        background: "#00000080",
        fontSize: Util.isMobileDevice ? "18px" : "24px",
        fontFamily: Util.font,
        textAlign: "left",
        verticalAlign: "top",
        userSelect: "text",
        marginBottom: "16px",
        padding: "16px"
    }

    let date = new Date(props.date);
    //add a day
    date.setDate(date.getDate() + 1);
    let now = new Date(Date.now());
    let dateHasPassed = date < now;

    if (dateHasPassed)
        containerStyle.color = Colors.midGrey;

    let showTicketsLink = props.ticketsUrl && !dateHasPassed;

    let ticketsLink = !props.ticketsUrl?.startsWith("http") ? props.ticketsUrl : 
        showTicketsLink ? <a href={props.ticketsUrl}>Tickets</a> : "";

    return (
        <div style={containerStyle}>
            {props.venue} // {props.location} // {props.date} // {props.time} {showTicketsLink ? "//" : ""} {ticketsLink} 
        </div>
    );
}
