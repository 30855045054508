import React, { CSSProperties } from 'react';
import logo from './logo.svg';
import './App.css';
import { NavButton } from './NavButton';
import { NavBar } from './NavBar';
import { Util } from './Util';

export function MusicSection() {

    let containerStyle : CSSProperties = 
    {
        textAlign: "center",
        verticalAlign: "middle",
        width: "100%",
        height: Util.isShortWindow ? "600px" : Util.sectionHeight,
        boxSizing: "border-box",
        overflow: "hidden",
        position: "relative"
    }

    let artworkStyle : CSSProperties = 
    {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        objectFit: "cover",
        userSelect: "none"
    }

    let musicContainerStyle : CSSProperties = 
    {
        position: "absolute",
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        width: Util.isMobileDevice ? "100%" : "55%", 
        color: "white",
        fontSize: Util.isMobileDevice ? "64px" : "96px",
        fontFamily: Util.font,
        textAlign: "left",
        userSelect: "none",
        display: "block",
        padding: "16px",
        boxSizing: "border-box"
    }

    let musicTitleStyle : CSSProperties = 
    {
        marginTop: "default",
        marginBottom: "0.25em"
    }

    let iFrameStyle : CSSProperties = 
    {
        display: "block"
    }

    return (
        <div style={containerStyle}>
            <img src={`${process.env.PUBLIC_URL}/images/grey_salad.jpg`} style={artworkStyle}></img>
            <div style={musicContainerStyle}>
                <p style={musicTitleStyle}>Music</p>
                <iframe style={iFrameStyle} src="https://open.spotify.com/embed/track/56D3W629bzgZDGOR5zq8TH?utm_source=generator" width="100%" height="80" frameBorder="0" allowFullScreen={false} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                <iframe style={iFrameStyle} src="https://open.spotify.com/embed/track/6wEWLfQbg3u32kZ8qu8OPS?utm_source=generator" width="100%" height="80" frameBorder="0" allowFullScreen={false} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                <iframe style={iFrameStyle} src="https://open.spotify.com/embed/track/1YdOcyuIVXDan5ifsLzD1P?utm_source=generator" width="100%" height="80" frameBorder="0" allowFullScreen={false} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                <iframe style={iFrameStyle} src="https://open.spotify.com/embed/track/1PdcK3np2VD1PrjjWzwIVD?utm_source=generator" width="100%" height="80" frameBorder="0" allowFullScreen={false} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>
        </div>
    );
}
